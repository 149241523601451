import * as React from 'react';
import { Helmet } from 'react-helmet';
import { createBrowserHistory } from 'history';
import { Router, Route } from 'react-router';
import { Switch } from 'react-router-dom';

// components
import { ScrollToTop } from './components';

// contexts
import {
  HeroTextContextProvider,
  MenuContextProvider,
  MenuIconContextProvider,
  SocialMediaMenuContextProvider
} from './contexts';

// pages
import { AboutPage, ContactPage, ErrorPage, HomePage, PortfolioPage, ServicesPage } from './pages';

// templates
import { MainTemplate } from './templates';

// styles
import './App.sass';

const history = createBrowserHistory();

export const App: React.SFC = () => {
  return (
    <Router history={history}>
      <ScrollToTop>
        <MainTemplate>
          <Helmet titleTemplate={`%s | ${ENV.appTitle}`} />
          <Switch>
            <Route path="/about" component={AboutPage} exact />
            <Route path="/contact" component={ContactPage} exact />
            <Route path="/portfolio" component={PortfolioPage} exact />
            <Route path="/services" component={ServicesPage} exact />
            <Route path="/" component={HomePage} exact />
            <Route component={ErrorPage} />
          </Switch>
        </MainTemplate>
      </ScrollToTop>
    </Router>
  );
};

export const AppProviders: React.SFC = ({ children }) => (
  <MenuIconContextProvider>
    <HeroTextContextProvider>
      <MenuContextProvider>
        <SocialMediaMenuContextProvider>{children}</SocialMediaMenuContextProvider>
      </MenuContextProvider>
    </HeroTextContextProvider>
  </MenuIconContextProvider>
);
