exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.Menu__menu__2tnQ0 {\n  color: #AB40FF;\n  font-family: 'Shree';\n  font-size: 11px;\n  padding: 0 0 0 30px; }\n\n.Menu__link__2iHoe {\n  display: block;\n  letter-spacing: 2px;\n  margin: 0 15px;\n  padding: 15px 15px 11px 15px;\n  text-transform: uppercase;\n  transition: background-color .25s, transform .25s; }\n  .Menu__link__2iHoe:hover {\n    text-decoration: none;\n    transform: scale(1.1); }\n\n.Menu__linkBlack__GYyZh {\n  color: #111; }\n\n.Menu__linkWhite__3Bmdc {\n  color: #FFF; }\n\n.Menu__list__1mOqO {\n  align-items: center;\n  display: flex;\n  list-style: none;\n  margin: 0;\n  padding: 0; }\n  @media (max-width: 815px) {\n    .Menu__list__1mOqO {\n      display: none; } }\n\n.Menu__listItem__zQbE5 {\n  display: inline-block;\n  font-family: CocoGoose;\n  text-align: center; }\n\n.Menu__selected__1-5pQ {\n  border-bottom: 3px #AB40FF solid;\n  color: #AB40FF; }\n  .Menu__selected__1-5pQ:hover {\n    transform: none; }\n\n.Menu__socialList__3sKry {\n  color: transparent; }\n\n@media (max-width: 1040px) {\n  .Menu__socialMediaMenu__fP1HV {\n    display: none; } }\n", ""]);

// exports
exports.locals = {
	"menu": "Menu__menu__2tnQ0",
	"link": "Menu__link__2iHoe",
	"linkBlack": "Menu__linkBlack__GYyZh",
	"linkWhite": "Menu__linkWhite__3Bmdc",
	"list": "Menu__list__1mOqO",
	"listItem": "Menu__listItem__zQbE5",
	"selected": "Menu__selected__1-5pQ",
	"socialList": "Menu__socialList__3sKry",
	"socialMediaMenu": "Menu__socialMediaMenu__fP1HV"
};