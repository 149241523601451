import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Examples2, ExamplesRedesign, PortfolioHero } from '../../components';

// context
import { MenuContext } from '../../contexts';

// styles
// import * as styles from './PortfolioPage.sass';

export const PortfolioPage: React.FC = () => {
  const { setSelectedMenuItem } = useContext(MenuContext);

  useEffect(() => {
    setSelectedMenuItem('portfolio');
  }, []);

  return (
    <div>
      <Helmet
        title="Portfolio"
        meta={[
          {
            name: 'description',
            content: 'Selected works by Austin Code Shop'
          },
          {
            name: 'keywords',
            content: 'ACS acs austincodeshop austin code shop works selected example examples'
          }
        ]}
      />
      <PortfolioHero />
      <ExamplesRedesign />
      <Examples2 showButton={false} />
    </div>
  );
};
