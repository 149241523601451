import * as React from 'react';
// import { Link } from 'react-router-dom';

// styles
import * as styles from './ExampleItem.sass';

interface IProps {
  description: string;
  image: 'eatPastry' | 'veganMexicanFood' | 'arlos' | 'hbs';
  key: string;
  name: string;
  url: string;
}

export const ExampleItem: React.FC<IProps> = props => {
  return (
    <a href={props.url} className={styles.exampleItem}>
      <div className={styles[props.image]} />
      <h3 className={styles.exampleItemName}>{props.name}</h3>
      <p className={styles.exampleItemDescription}>{props.description}</p>
      {/* <Link className={styles.featureItemLink} to={'/services'}>
        LEARN MORE
      </Link> */}
    </a>
  );
};
