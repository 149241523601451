exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.Button__black__108_7 {\n  background-color: #111 !important;\n  color: #FFF !important; }\n\n.Button__button__lit55 {\n  border: none;\n  border-radius: 50px;\n  cursor: pointer;\n  display: inline-block;\n  font-family: CocoGoose;\n  font-size: 1rem;\n  margin: 30px 0;\n  padding: 15px 40px;\n  position: relative;\n  text-decoration: none;\n  transition: transform 0.25s;\n  white-space: nowrap; }\n  .Button__button__lit55:hover {\n    text-decoration: none;\n    transform: scale(1.1); }\n  .Button__button__lit55[disabled] {\n    background-color: #87898C !important;\n    cursor: no-drop; }\n    .Button__button__lit55[disabled]:hover {\n      transform: none; }\n\n.Button__container__U1Smi {\n  align-items: center;\n  display: flex; }\n\n.Button__icon__2LHB8 {\n  background-position: center center;\n  background-repeat: no-repeat;\n  background-size: contain;\n  height: 24px;\n  margin-left: 10px;\n  width: 24px; }\n\n.Button__primary__34_7t {\n  background-color: #AB40FF !important;\n  color: #FFF !important; }\n\n.Button__secondary__2Fl33 {\n  background-color: #627B8C !important;\n  color: #111 !important; }\n\n.Button__text__3Xcs4 {\n  flex-grow: 1;\n  margin-top: 2px; }\n\n.Button__white__1dAtp {\n  background-color: #FFF !important;\n  color: #111 !important; }\n", ""]);

// exports
exports.locals = {
	"black": "Button__black__108_7",
	"button": "Button__button__lit55",
	"container": "Button__container__U1Smi",
	"icon": "Button__icon__2LHB8",
	"primary": "Button__primary__34_7t",
	"secondary": "Button__secondary__2Fl33",
	"text": "Button__text__3Xcs4",
	"white": "Button__white__1dAtp"
};