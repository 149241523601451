import * as React from 'react';
import { Fade } from 'react-reveal';

// styles
import * as styles from './HeroScrollDown.sass';

export const HeroScrollDown: React.FC = () => (
  <div className={styles.scrollDown}>
    <Fade up delay={500}>
      <div className={styles.scrollDownText}>Scroll Down</div>
    </Fade>
    <Fade up delay={1000}>
      <a className={styles.scrollDownIcon} />
    </Fade>
  </div>
);
