import * as React from 'react';
import { Fade } from 'react-reveal';

// components
import { Container } from '../../components';

// styles
import * as styles from './Technologies.sass';

interface IProps {
  className?: string;
}

export const Technologies: React.SFC<IProps> = ({ children }) => {
  const technologiesInfo = [
    {
      title: 'React',
      imageUrl: '../../assets/images/technologies/react.svg',
      fade: 200
    },
    {
      title: 'Node.js',
      imageUrl: '../../assets/images/technologies/nodejs.svg',
      fade: 300
    },
    {
      title: 'Angular',
      imageUrl: '../../assets/images/technologies/angular.svg',
      fade: 350
    },
    {
      title: 'Express',
      imageUrl: '../../assets/images/technologies/express.svg',
      fade: 350
    },
    {
      title: 'Android',
      imageUrl: '../../assets/images/technologies/android.svg',
      fade: 400
    },
    {
      title: 'iOS',
      imageUrl: '../../assets/images/technologies/ios.svg',
      fade: 600
    },
    {
      title: 'Python',
      imageUrl: '../../assets/images/technologies/python.svg',
      fade: 200
    },
    {
      title: 'Swift',
      imageUrl: '../../assets/images/technologies/swift.svg',
      fade: 300
    },
    {
      title: 'Java',
      imageUrl: '../../assets/images/technologies/java.svg',
      fade: 350
    },
    {
      title: 'Wordpress',
      imageUrl: '../../assets/images/technologies/wordpress.svg',
      fade: 350
    },
    {
      title: 'Webflow',
      imageUrl: '../../assets/images/technologies/webflow.svg',
      fade: 400
    },
    {
      title: 'Shopify',
      imageUrl: '../../assets/images/technologies/shopify.svg',
      fade: 600
    },
    {
      title: 'Amazon Web Services',
      imageUrl: '../../assets/images/technologies/aws.svg',
      fade: 350
    },
    {
      title: 'Google Cloud Platform',
      imageUrl: '../../assets/images/technologies/gc.svg',
      fade: 400
    },
    {
      title: 'Microsoft Azure',
      imageUrl: '../../assets/images/technologies/azure.svg',
      fade: 600
    }
  ];

  return (
    <div className={styles.container}>
      <Container>
        <Fade up delay={250}>
          <div className={styles.subtitle}>Technologies</div>
        </Fade>
        <Fade up delay={300}>
          <div className={styles.title}>From Frontend to Backend</div>
        </Fade>
        <Fade up delay={350}>
          <div className={styles.description}>
            We work with many languages and frameworks including:
          </div>
        </Fade>
        <div className={styles.tiles}>
          {technologiesInfo.map(service => {
            return (
              <div className={styles.tile} key={service.title}>
                <img className={styles.tileImage} src={service.imageUrl} />
                <div className={styles.tileTitle}>{service.title}</div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};
