import * as React from 'react';
import * as classNames from 'classnames';
import { useState, useEffect } from 'react';

// components
import { Heading } from '..';

// styles
import * as styles from './Testimonials.sass';

interface IProps {
  className?: string;
}

export const Testimonials: React.SFC<IProps> = ({ children }) => {
  const [slideshowIndex, setSlideshowIndex] = useState(0);

  const testimonials = [
    {
      quote:
        'Austin Code Shop is the most diligent company we’ve worked with. They listen to what you’re saying and put ideas into action, which I’ve found to be a lost art in business. They’ve never failed us with their professionalism and ability to create.',
      client: 'Jessie Elias, Co-Founder/CEO',
      company: 'EatPastry',
      companyLogo: '../../assets/images/portfolio/eatpastry-logo.jpg'
    },
    {
      quote:
        'Austin Code Shop took the time to understand my business and created a custom website that our customers love! The feedback has been overwhelmingly positive and the traffic to our website has skyrocketed! This will be our go-to company for future updates!',
      client: 'Mitra Khan, President',
      company: 'Highway Barricades & Services',
      companyLogo: '../../assets/images/portfolio/hbs-logo.svg'
    }
  ];

  const timer = setInterval(() => {
    nextSlide();
  }, 10 * 1000);

  const nextSlide = () => {
    if (slideshowIndex === testimonials.length - 1) {
      setSlideshowIndex(0);
    } else {
      setSlideshowIndex(slideshowIndex + 1);
    }
    clearInterval(timer);
  };

  // const previousSlide = () => {
  //   if (slideshowIndex === 0) {
  //     setSlideshowIndex(testimonials.length - 1);
  //   } else {
  //     setSlideshowIndex(slideshowIndex - 1);
  //   }
  //   clearInterval(timer);
  // };

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, [slideshowIndex]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.containerDotsBackground} />
      <div className={styles.container}>
        <Heading level={4} className={styles.title}>
          What our customers are saying
        </Heading>
        <div
          className={classNames(
            styles.slideshow,
            slideshowIndex === 0 ? styles.imageDescriptionFadein1 : null,
            slideshowIndex === 1 ? styles.imageDescriptionFadein2 : null,
            slideshowIndex === 2 ? styles.imageDescriptionFadein3 : null,
            slideshowIndex === 3 ? styles.imageDescriptionFadein4 : null,
            slideshowIndex === 4 ? styles.imageDescriptionFadein5 : null,
            slideshowIndex === 5 ? styles.imageDescriptionFadein6 : null
          )}
        >
          <div className={styles.quote}>{`"${testimonials[slideshowIndex].quote}"`}</div>
          <div className={styles.flexContainer}>
            <div>
              <div className={styles.clientName}>{testimonials[slideshowIndex].client}</div>
              <div className={styles.companyName}>{testimonials[slideshowIndex].company}</div>
            </div>
            <img src={testimonials[slideshowIndex].companyLogo} className={styles.companyLogo} />
          </div>
          <div className={styles.indicatorContainer}>
            {testimonials.map((example, index) => (
              <button
                className={classNames(
                  styles.indicator,
                  slideshowIndex === index ? styles.indicatorActive : null
                )}
                key={example.client}
                onClick={() => setSlideshowIndex(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
