exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.About__aboutImage__BvjSE {\n  width: 500px;\n  height: 300px;\n  border-radius: 50px;\n  background-color: orange;\n  margin: 0 auto;\n  max-width: 100%;\n  background-image: url(\"../../assets/images/about/about.svg\");\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  margin-top: 50px; }\n  @media (max-width: 540px) {\n    .About__aboutImage__BvjSE {\n      width: 100%;\n      height: calc((100vw - 40px) * 0.6); } }\n\n.About__container__3dqi6 {\n  background-color: #AB40FF;\n  margin: 0;\n  padding: 100px 20px 100px; }\n\n.About__description__3tDbj {\n  font-size: 1rem;\n  font-weight: 100;\n  color: #FFF;\n  width: 100%;\n  text-align: center; }\n\n.About__subtitle__2FmEh {\n  font-size: 0.9rem;\n  font-weight: 100;\n  text-transform: uppercase;\n  color: #EBEBEB;\n  letter-spacing: 0.3rem;\n  width: 100%;\n  text-align: center; }\n\n.About__title__VI6CQ {\n  width: 100%;\n  text-align: center;\n  font-size: 2rem;\n  font-family: CocoGoose;\n  color: #FFF;\n  margin: 25px 0; }\n", ""]);

// exports
exports.locals = {
	"aboutImage": "About__aboutImage__BvjSE",
	"container": "About__container__3dqi6",
	"description": "About__description__3tDbj",
	"subtitle": "About__subtitle__2FmEh",
	"title": "About__title__VI6CQ"
};