import * as React from 'react';
import { Fade } from 'react-reveal';

// components
import { Container } from '../../components';

// styles
import * as styles from './About.sass';

interface IProps {
  className?: string;
}

export const About: React.SFC<IProps> = ({ children }) => {
  return (
    <div className={styles.container}>
      <Container>
        <Fade up delay={250}>
          <div className={styles.subtitle}>We Are Austin Code Shop</div>
        </Fade>
        <Fade up delay={300}>
          <div className={styles.title}>Software Makers</div>
        </Fade>
        <Fade up delay={350}>
          <div className={styles.description}>We build custom software for companies.</div>
        </Fade>
        <Fade up delay={350}>
          <div className={styles.aboutImage} />
        </Fade>
      </Container>
    </div>
  );
};
