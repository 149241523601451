exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".HeroImage__heroImage__Lk325 {\n  background-image: url(\"/assets/images/hero/web.svg\");\n  background-position: center center;\n  background-repeat: no-repeat;\n  background-size: contain;\n  flex: 0 0 50%;\n  height: 350px; }\n  @media (max-width: 576px) {\n    .HeroImage__heroImage__Lk325 {\n      margin-top: 50px; } }\n", ""]);

// exports
exports.locals = {
	"heroImage": "HeroImage__heroImage__Lk325"
};