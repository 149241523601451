exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.FeatureImage__automation__23K0R,\n.FeatureImage__cloudSoftware__3rNmu,\n.FeatureImage__mobileApps__326pB,\n.FeatureImage__website__17sDp {\n  background-color: #BDBEC0;\n  background-position: center center;\n  background-size: cover;\n  border-radius: 10px;\n  height: 300px;\n  padding: 0 25px;\n  width: 100%; }\n\n.FeatureImage__automation__23K0R {\n  background-image: url(\"./assets/images/features/automation.svg\"); }\n\n.FeatureImage__cloudSoftware__3rNmu {\n  background-image: url(\"/assets/images/features/cloud.svg\"); }\n\n.FeatureImage__mobileApps__326pB {\n  background-image: url(\"/assets/images/features/app.svg\"); }\n\n.FeatureImage__website__17sDp {\n  background-image: url(\"/assets/images/features/website.svg\"); }\n", ""]);

// exports
exports.locals = {
	"automation": "FeatureImage__automation__23K0R",
	"cloudSoftware": "FeatureImage__cloudSoftware__3rNmu",
	"mobileApps": "FeatureImage__mobileApps__326pB",
	"website": "FeatureImage__website__17sDp"
};