import * as React from 'react';
import * as classNames from 'classnames';
import { useContext, useEffect } from 'react';

// components
import { Menu } from '../../components';
import { ResponsiveMenu } from '../responsiveMenu/ResponsiveMenu';

// contexts
import { MenuContext, MenuIconContext } from '../../contexts';

// styles
import * as styles from './Header.sass';

export const Header: React.FC = () => {
  const { selectedMenuItem } = useContext(MenuContext);
  const { menuIcon, setMenuIcon } = useContext(MenuIconContext);

  // const headerInfo: any = {
  //   works: { title: 'Works', subtitle: 'Our favorite projects for our favorite companies.' },
  //   services: { title: 'Services', subtitle: 'How can custom development help you?' },
  //   about: { title: 'About', subtitle: 'Who we are.' },
  //   contact: { title: 'Contact', subtitle: 'Get in touch with us.' }
  // };

  useEffect(() => {
    const menuButton = document.getElementById('menuLinks');
    const menuBackground = document.getElementById('menuBackground');
    setMenuIcon('../../assets/images/icons/menu-button.png');
    menuButton.setAttribute('style', 'width: 0');
    menuBackground.setAttribute('style', 'width: 0');
  }, []);

  useEffect(() => {
    const menuButton = document.getElementById('menuLinks');
    const menuBackground = document.getElementById('menuBackground');
    if (selectedMenuItem) {
      setMenuIcon('../../assets/images/icons/menu-button.png');
      menuButton.setAttribute('style', 'width: 0');
      menuBackground.setAttribute('style', 'width: 0');
    }
  }, [selectedMenuItem]);

  return (
    <header
      // className={classNames(
      //   selectedMenuItem === 'home' ? styles.headerHome : null,
      //   selectedMenuItem === 'works' ? styles.headerWorks : null,
      //   selectedMenuItem === 'about' ? styles.headerAbout : null,
      //   selectedMenuItem === 'contact' ? styles.headerContact : null,
      //   selectedMenuItem === 'services' ? styles.headerServices : null
      // )}
      className={styles.header}
    >
      <div className={styles.menuContainer}>
        <h1>
          <a
            className={classNames(
              styles.title,
              styles.logoBlack
              // selectedMenuItem === 'home' ? styles.logoBlack : styles.logoWhite
            )}
            href="/"
          >
            Austin Code Shop
          </a>
        </h1>
        <Menu />
        <button
          style={{
            backgroundImage: `url('${menuIcon}')`
          }}
          id="#menuIcon"
          onClick={() => {
            const menuButton = document.getElementById('menuLinks');
            const menuBackground = document.getElementById('menuBackground');
            const body = document.getElementById('body');
            if (menuButton.style.width == '60vw') {
              menuButton.setAttribute('style', 'width: 0');
              setMenuIcon('../../assets/images/icons/menu-button.png');
              body.style.overflow = 'visible';
              body.style.position = 'static';
            } else {
              menuButton.setAttribute('style', 'width: 60vw');
              setMenuIcon('../../assets/images/icons/close-button.png');
              body.style.overflow = 'hidden';
              // body.style.position = 'fixed';
            }
            if (menuBackground.style.width == '60vw') {
              menuBackground.setAttribute('style', 'width: 0');
              setMenuIcon('../../assets/images/icons/menu-button.png');
              body.style.overflow = 'visible';
              body.style.position = 'static';
            } else {
              menuBackground.setAttribute('style', 'width: 60vw');
              setMenuIcon('../../assets/images/icons/close-button.png');
              body.style.overflow = 'hidden';
              // body.style.position = 'fixed';
            }
          }}
          className={styles.menuIconButton}
        />
        <ResponsiveMenu />
      </div>
      {/* {selectedMenuItem === 'home' ? null : (
        <div className={styles.titleContainer}>
          <div>
            <Fade up delay={200}>
              <div className={styles.headerTitle}>{headerInfo[selectedMenuItem].title}</div>
            </Fade>
            <Fade up delay={400}>
              <div
                className={classNames(
                  styles.headerSubtitle,
                  selectedMenuItem === 'services' || selectedMenuItem === 'contact'
                    ? styles.fontDark
                    : null
                )}
              >
                {headerInfo[selectedMenuItem].subtitle}
              </div>
            </Fade>
          </div>
        </div>
      )} */}
    </header>
  );
};
