import * as React from 'react';
import { Link } from 'react-router-dom';

// components
import { Heading } from '../../components';

// styles
import * as styles from './ErrorPage.sass';

export const ErrorPage: React.FC = () => (
  <div className={styles.wrapper}>
    <Heading level={1}>Page Not Found</Heading>
    <div className={styles.icon} />
    <p><Link to="/">Click here</Link> to go back home.</p>
  </div>
);
