import * as RubberBand from 'react-reveal/RubberBand';
import * as React from 'react';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

// components
import { Button, Heading } from '../../components';

// styles
import * as styles from './Contact.sass';

export const Contact: React.FC = () => {
  const [captcha, setCaptcha] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  async function handleSubmit(event: any) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await fetch('https://api.austincodeshop.com/contact', {
        method: 'POST',
        body: JSON.stringify({
          name,
          email,
          message,
          subject: 'Message from ACS customer'
        })
      });
      setIsComplete(true);
    } catch (error) {
      setErrorMessage(error.message);
    }
    setIsLoading(false);
  }

  const handleCaptcha = (value: string) => {
    setCaptcha(value);
  };

  return (
    <div className={styles.contact}>
      <Heading className={styles.title} level={2}>
        Shoot us a message
      </Heading>
      <div className={styles.subtitle}>We'd love to hear from you.</div>
      <div className={styles.container}>
        <RubberBand>
          <div className={styles.contactImage} />
        </RubberBand>
        <form className={styles.contactForm} onSubmit={handleSubmit}>
          <label className={styles.formLabel} htmlFor="nameInput">
            NAME
          </label>
          <input
            className={styles.formInput}
            type="text"
            id="nameInput"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <label className={styles.formLabel} htmlFor="emailInput">
            EMAIL
          </label>
          <input
            className={styles.formInput}
            type="text"
            id="emailInput"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label className={styles.formLabel} htmlFor="messageInput">
            MESSAGE
          </label>
          <textarea
            className={styles.formTextarea}
            id="messageInput"
            placeholder="How can we help you?"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          <div className={styles.captchaContainer}>
            <ReCAPTCHA
              sitekey="6LdJzqUUAAAAAG2BcyMw-mOk2ZkX_vFrg0DI5kse"
              onChange={handleCaptcha}
            />
          </div>
          <div>
            <Button
              className={styles.button}
              disabled={!captcha || !name || !email || !message}
              type="submit"
            >
              {isLoading ? 'Loading...' : 'Send Message'}
            </Button>
          </div>
          {isComplete && <div className={styles.formMessage}>Thank you!</div>}
          {errorMessage && <div className={styles.formMessage}>{errorMessage}</div>}
        </form>
      </div>
    </div>
  );
};
