exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.Services__container___Rfse {\n  background-color: #AB40FF;\n  margin: 150px 0 0 0;\n  padding: 100px 0; }\n\n.Services__description__1u7LC {\n  color: #BDBEC0;\n  font-size: 1rem;\n  font-weight: 100;\n  text-align: center;\n  width: 100%; }\n\n.Services__subtitle__2-l10 {\n  font-size: 0.9rem;\n  font-weight: 100;\n  text-transform: uppercase;\n  color: #FFF;\n  letter-spacing: 0.3rem;\n  width: 100%;\n  text-align: center; }\n\n.Services__tile__2qSPv {\n  width: calc((1000px - 150px) / 3);\n  display: inline-block;\n  padding: 25px 25px 50px 25px;\n  color: #BDBEC0;\n  border-radius: 20px;\n  margin: 25px;\n  font-size: 1rem;\n  text-align: center; }\n  @media (max-width: 700px) {\n    .Services__tile__2qSPv {\n      width: calc((1000px - 150px) / 2); } }\n\n.Services__tileDescription__2Gn9y {\n  color: #EBEBEB;\n  font-weight: 100;\n  font-size: 0.9rem;\n  line-height: 1.3rem; }\n\n.Services__tileImage__kjZgf {\n  width: 96px;\n  height: 96px;\n  margin: 0 auto; }\n  @media (max-width: 1000px) {\n    .Services__tileImage__kjZgf {\n      width: 120px;\n      height: 120px; } }\n  @media (max-width: 700px) {\n    .Services__tileImage__kjZgf {\n      width: 200px;\n      height: 200px; } }\n\n.Services__tileTitle__2wyXi {\n  color: #FFF;\n  font-family: CocoGoose;\n  font-size: 1.1rem;\n  margin: 18px 0; }\n\n.Services__tiles__3cyDO {\n  list-style: none;\n  margin: 50px 0 0 0;\n  padding: 0;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center; }\n\n.Services__title__3WHMl {\n  color: #FFF;\n  margin: 25px 0;\n  font-size: 2rem;\n  font-family: CocoGoose;\n  text-align: center;\n  text-transform: lowercase;\n  width: 100%; }\n", ""]);

// exports
exports.locals = {
	"container": "Services__container___Rfse",
	"description": "Services__description__1u7LC",
	"subtitle": "Services__subtitle__2-l10",
	"tile": "Services__tile__2qSPv",
	"tileDescription": "Services__tileDescription__2Gn9y",
	"tileImage": "Services__tileImage__kjZgf",
	"tileTitle": "Services__tileTitle__2wyXi",
	"tiles": "Services__tiles__3cyDO",
	"title": "Services__title__3WHMl"
};