import * as classNames from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';

// components
import { FeatureImage } from '../index';

// styles
import * as styles from './FeatureItem.sass';

interface IProps {
  description: string;
  image: 'automation' | 'cloudSoftware' | 'mobileApps' | 'website';
  key: string;
  name: string;
  position: 'left' | 'right';
}

export const FeatureItem: React.FC<IProps> = ({ description, image, name, position = 'left' }) => {
  return (
    <div className={styles.featureItem}>
      <div
        className={classNames(styles.imageContainer, position === 'right' ? styles.right : null)}
      >
        <FeatureImage image={image} />
      </div>
      <div className={styles.featureDetails}>
        <div className={styles.featureDetailsContainer}>
          <h3 className={styles.featureItemName}>{name}</h3>
          <p className={styles.featureItemDescription}>{description}</p>
          <Link className={styles.featureItemLink} to={'/services'}>
            LEARN MORE
          </Link>
        </div>
      </div>
    </div>
  );
};
