import * as React from 'react';
import { useState, useEffect } from 'react';

// components
import { Heading } from '..';

// styles
import * as styles from './AboutHero.sass';

export const AboutHero: React.FC = () => {
  const [width, setWidth] = useState(0);

  const aboutContent = [
    {
      subheader: 'Remote.',
      description:
        'We believe that remote work is best. Our team is fully distributed which means instead of spending hours in traffic, we’re ready and working for our clients at the very start of each day! We offer our team flexibility to work from home (or anywhere!) with a fixed workday of 8-5 CST because a team with an actual work-life balance is a happy one.',
      imageUrl: '../../assets/images/about/video-call.svg'
    },
    {
      subheader: 'Mentorship.',
      description:
        'We seek out career changers, bootcamp graduates and junior engineers to provide dedicated mentorship from a senior engineer with 20 years of experience. Our theory is that decades of knowledge can be transferred, good software design can be taught - and the proof is in our work.',
      imageUrl: '../../assets/images/about/team.svg'
    },
    {
      subheader: 'Balanced.',
      description:
        'Even better, a career changer brings experience of other industries to the table. They’ve sacrificed to join tech. For any company seeking candidates whose experience and perspective are unique - you need only to look to junior engineers, create an environment of support and build incredible things together!',
      imageUrl: '../../assets/images/about/team-vote.svg'
    }
  ];

  const handleScroll = () => {
    const newWidth = window.scrollY * 0.1;

    setWidth(newWidth);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Heading level={2}>Our people are the difference</Heading>
        <div className={styles.subtitle}>With the right people, anything is possible.</div>

        <div className={styles.imageGrid}>
          {aboutContent.map(info => {
            return (
              <div className={styles.flexItem} key={info.subheader}>
                <div className={styles.flexContainer}>
                  <img
                    style={{
                      width: `calc(${width}px + 80%)`
                    }}
                    className={styles.imageCenter}
                    src={info.imageUrl}
                  />
                </div>
                <div>
                  <h4 className={styles.subheader}>{info.subheader}</h4>
                  <p className={styles.description}>{info.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
