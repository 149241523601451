exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.Container__container__ZEm5z {\n  margin: 0 auto;\n  max-width: 1000px; }\n", ""]);

// exports
exports.locals = {
	"container": "Container__container__ZEm5z"
};