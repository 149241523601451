import * as React from 'react';
import * as classNames from 'classnames';

// styles
import * as styles from './Heading.sass';

interface IProps {
  className?: string;
  level?: number;
}

export const Heading: React.FC<IProps> = ({ children, className = '', level = 1 }) => {
  const headingClassNames = classNames(styles.heading, className);
  if (level === 1) {
    return (
      <h1 className={headingClassNames}>
        {children}
      </h1>
    );
  }
  if (level === 2) {
    return (
      <h2 className={headingClassNames}>
        {children}
      </h2>
    );
  }
  if (level === 3) {
    return (
      <h3 className={headingClassNames}>
        {children}
      </h3>
    );
  }
  if (level === 4) {
    return (
      <h4 className={headingClassNames}>
        {children}
      </h4>
    );
  }
  if (level === 5) {
    return (
      <h5 className={headingClassNames}>
        {children}
      </h5>
    );
  }
  if (level === 6) {
    return (
      <h6 className={headingClassNames}>
        {children}
      </h6>
    );
  }
  
  return (
    <div className={headingClassNames}>
      {children}
    </div>
  );
};
