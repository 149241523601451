import * as React from 'react';

// styles
import * as styles from './FeatureImage.sass';

interface IProps {
  image: 'automation' | 'cloudSoftware' | 'mobileApps' | 'website'
}

export const FeatureImage: React.FC<IProps> = props => {
  return <div className={ styles[props.image] } />;
};
