import * as React from 'react';
import { Dispatch, SetStateAction, createContext, useState } from 'react';

interface IProps {
  menuIcon: string;
  setMenuIcon: Dispatch<SetStateAction<string>>;
}

export const MenuIconContext = createContext<IProps>({
  menuIcon: '',
  setMenuIcon: () => {}
});

export const MenuIconContextProvider: React.SFC = props => {
  const [menuIcon, setMenuIcon] = useState('../../assets/images/icons/menu-button.png');

  const value = { menuIcon, setMenuIcon };

  return <MenuIconContext.Provider value={value}>{props.children}</MenuIconContext.Provider>;
};

export const MenuIconContextConsumer = MenuIconContext.Consumer;
