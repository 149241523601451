exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.Features__container__2PkDa {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  margin: 0 auto;\n  max-width: 1000px; }\n\n.Features__services__1aSeT {\n  background-color: #FFF;\n  height: auto;\n  padding: 150px 20px 50px 20px; }\n  @media (max-width: 768px) {\n    .Features__services__1aSeT {\n      padding: 150px 20px; } }\n", ""]);

// exports
exports.locals = {
	"container": "Features__container__2PkDa",
	"services": "Features__services__1aSeT"
};