import * as React from 'react';
import { Fade } from 'react-reveal';

// components
import { Heading } from '..';

// styles
import * as styles from './Subheading.sass';

interface IProps {
  subtitle?: string;
  title?: string;
  description?: string;
}

export const Subheading: React.FC<IProps> = props => (
  <div className={styles.subheading}>
    <Fade up delay={200}>
      <h2 className={styles.subtitle}>{props.subtitle}</h2>
    </Fade>
    <Fade up delay={250}>
      <Heading level={2} className={styles.title}>{props.title}</Heading>
    </Fade>
    <Fade up delay={300}>
      <p className={styles.description}>{props.description}</p>
    </Fade>
  </div>
);
