exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.CookieNotice__container__3EHWT {\n  align-items: center;\n  display: flex;\n  margin: 0 auto;\n  max-width: 1000px; }\n\n.CookieNotice__button__c9pWc {\n  background-color: #111;\n  border: 1px solid #111;\n  border-radius: 20px;\n  color: #FFF;\n  cursor: pointer;\n  font-size: 16px;\n  height: 40px;\n  padding: 6px 12px;\n  width: 150px; }\n\n.CookieNotice__link__3EiOz {\n  color: #BDBEC0;\n  font-weight: bold; }\n  .CookieNotice__link__3EiOz:hover {\n    cursor: pointer;\n    text-decoration: none; }\n\n.CookieNotice__text__2IDcv {\n  color: #87898C;\n  flex-grow: 1;\n  font-size: 14px;\n  margin: 0 10px 0 auto; }\n  @media (max-width: 1000px) {\n    .CookieNotice__text__2IDcv {\n      font-size: 12px;\n      line-height: 18px; } }\n\n.CookieNotice__wrapper__18uaD {\n  background-color: rgba(0, 0, 0, 0.8);\n  bottom: -90px;\n  left: 0;\n  opacity: 0;\n  padding: 10px 0;\n  position: fixed;\n  transition: bottom 1s, opacity 1s;\n  width: 100%;\n  z-index: 999999; }\n  .CookieNotice__wrapper__18uaD.CookieNotice__unwrapped___xLaL {\n    bottom: 0;\n    opacity: 1; }\n  @media (max-width: 1000px) {\n    .CookieNotice__wrapper__18uaD {\n      padding: 10px; } }\n", ""]);

// exports
exports.locals = {
	"container": "CookieNotice__container__3EHWT",
	"button": "CookieNotice__button__c9pWc",
	"link": "CookieNotice__link__3EiOz",
	"text": "CookieNotice__text__2IDcv",
	"wrapper": "CookieNotice__wrapper__18uaD",
	"unwrapped": "CookieNotice__unwrapped___xLaL"
};