exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.FooterLink__footerLink__nxLFw {\n  color: #BDBEC0;\n  font-size: 14px;\n  font-weight: 200;\n  padding-bottom: 4px; }\n  .FooterLink__footerLink__nxLFw:hover {\n    color: #FFF;\n    text-decoration: none; }\n  @media (max-width: 650px) {\n    .FooterLink__footerLink__nxLFw {\n      font-size: 16px; } }\n", ""]);

// exports
exports.locals = {
	"footerLink": "FooterLink__footerLink__nxLFw"
};