exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.HeroDetails__container__3n2OW {\n  display: flex;\n  flex: 0 0 50%;\n  flex-direction: column;\n  justify-content: center; }\n  @media (max-width: 900px) {\n    .HeroDetails__container__3n2OW {\n      padding-right: 20px; } }\n\n.HeroDetails__textLoop__1zqms,\n.HeroDetails__title__25N8G {\n  font-size: 42px;\n  font-weight: normal;\n  font-family: 'CocoGoose';\n  text-align: right; }\n  @media (max-width: 768px) {\n    .HeroDetails__textLoop__1zqms,\n    .HeroDetails__title__25N8G {\n      font-size: 1.8rem; } }\n  @media (max-width: 576px) {\n    .HeroDetails__textLoop__1zqms,\n    .HeroDetails__title__25N8G {\n      text-align: center; } }\n\n.HeroDetails__textLoop__1zqms {\n  color: #AB40FF;\n  display: flex;\n  justify-content: flex-end; }\n\n.HeroDetails__textLoopBlack__1Ebtj {\n  color: #111; }\n", ""]);

// exports
exports.locals = {
	"container": "HeroDetails__container__3n2OW",
	"textLoop": "HeroDetails__textLoop__1zqms",
	"title": "HeroDetails__title__25N8G",
	"textLoopBlack": "HeroDetails__textLoopBlack__1Ebtj"
};