exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700);", ""]);

// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.ResponsiveMenu__button__2eSng {\n  margin: 10px auto 0 auto; }\n  @media screen and (max-width: 680px) {\n    .ResponsiveMenu__button__2eSng {\n      margin: 10px auto 0 40px; } }\n\n.ResponsiveMenu__main__B_j-y {\n  font-family: 'Roboto', sans-serif;\n  font-size: 1.5em;\n  position: fixed;\n  bottom: 0;\n  right: 0;\n  display: none;\n  text-align: right;\n  width: 0;\n  height: 100vh;\n  z-index: 20;\n  transition: width 0.5s; }\n  @media screen and (max-width: 815px) {\n    .ResponsiveMenu__main__B_j-y {\n      display: block; } }\n\n.ResponsiveMenu__menuLinks__1nqM- {\n  width: 0;\n  height: 100vh;\n  display: grid;\n  grid-template-columns: 1fr;\n  box-shadow: -12px 3px 21px -8px rgba(2, 36, 74, 0.6);\n  transition: 0.7s ease;\n  background: #111;\n  float: right;\n  align-content: center;\n  opacity: 1;\n  transition: width 0.5s ease-in-out;\n  word-wrap: none;\n  white-space: nowrap; }\n\n.ResponsiveMenu__menuLink__1albB {\n  color: #FFF;\n  margin: 0 auto;\n  text-align: center;\n  text-transform: uppercase;\n  padding: 10px 0 5px 0;\n  text-decoration: none;\n  font-family: 'Roboto', sans-serif;\n  font-weight: 200;\n  word-wrap: none;\n  white-space: nowrap; }\n  @media screen and (max-width: 640px) {\n    .ResponsiveMenu__menuLink__1albB {\n      text-align: left;\n      padding: 10px 40px 5px 40px;\n      margin: 0 auto 0 0; } }\n", ""]);

// exports
exports.locals = {
	"button": "ResponsiveMenu__button__2eSng",
	"main": "ResponsiveMenu__main__B_j-y",
	"menuLinks": "ResponsiveMenu__menuLinks__1nqM-",
	"menuLink": "ResponsiveMenu__menuLink__1albB"
};