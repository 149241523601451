import * as React from 'react';
import { Dispatch, SetStateAction, createContext, useState } from 'react';

interface IProps {
  socialMediaMenuItems: ISocialMediaMenu[];
  setMenuItems: Dispatch<SetStateAction<ISocialMediaMenu[]>>;
}

interface ISocialMediaMenu {
  href: string;
  platform: 'facebook' | 'github' | 'twitter';
  text: string;
}

export const SocialMediaMenuContext = createContext<IProps>({
  socialMediaMenuItems: [],
  setMenuItems: () => {}
});

export const SocialMediaMenuContextProvider: React.SFC = props => {
  const [socialMediaMenuItems, setMenuItems] = useState<ISocialMediaMenu[]>([
    {
      href: 'https://twitter.com/austincodeshop',
      platform: 'twitter',
      text: 'Twitter'
    },
    {
      href: 'https://facebook.com/',
      platform: 'facebook',
      text: 'Facebook'
    },
    {
      href: 'https://github.com/austincodeshop',
      platform: 'github',
      text: 'GitHub'
    }
  ]);

  const value = { socialMediaMenuItems, setMenuItems };

  return (
    <SocialMediaMenuContext.Provider value={value}>
      {props.children}
    </SocialMediaMenuContext.Provider>
  );
};

export const SocialMediaMenuContextConsumer = SocialMediaMenuContext.Consumer;
