import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

// components
import { CTABanner, Services, ServiceFeatures, Technologies, ServicesHero } from '../../components';

// context
import { MenuContext } from '../../contexts';

// styles
// import * as styles from './ServicesPage.sass';

export const ServicesPage: React.FC = () => {
  const { setSelectedMenuItem } = useContext(MenuContext);

  useEffect(() => {
    setSelectedMenuItem('services');
  }, []);

  const bannerProps = {
    buttonText: 'Contact Us',
    subtitle: 'READY TO TALK ABOUT YOUR TECH?',
    text: 'You dream it. We build it.',
    title: "Let's Work Together"
  };

  return (
    <div>
      <Helmet
        title="Services"
        meta={[
          {
            name: 'description',
            content: 'Austin Code Shop Services'
          },
          {
            name: 'keywords',
            content: 'ACS acs austincodeshop austin code shop services'
          }
        ]}
      />
      <ServicesHero />
      <Services />
      <ServiceFeatures />
      <Technologies />
      <CTABanner
        buttonText={bannerProps.buttonText}
        subtitle={bannerProps.subtitle}
        text={bannerProps.text}
        title={bannerProps.title}
      />
    </div>
  );
};
