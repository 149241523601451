exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.Examples__container__330et {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  margin: 0 auto;\n  max-width: 1000px; }\n\n.Examples__examples__3PzDP {\n  background-color: #BDBEC0;\n  padding: 150px 40px; }\n", ""]);

// exports
exports.locals = {
	"container": "Examples__container__330et",
	"examples": "Examples__examples__3PzDP"
};