import * as React from 'react';
import { Fade } from 'react-reveal';

// components
import { Container } from '../../components';

// styles
import * as styles from './Services.sass';

interface IProps {
  className?: string;
}

export const Services: React.SFC<IProps> = ({ children }) => {
  const servicesInfo = [
    {
      title: 'Web Development',
      description: 'Websites, Web Apps and APIs for Ecommerce, Booking, Real Estate and more!',
      imageUrl: '../../assets/images/services/web2.svg',
      fade: 200
    },
    {
      title: 'Mobile Development',
      description:
        'Native iOS and Android apps that deliver a high quality experience for your customers.',
      imageUrl: '../../assets/images/services/mobile.svg',
      fade: 300
    },
    {
      title: 'Business Development',
      description: 'CRMs, WHMs, CMSs, Oh my! Anything you need for your business workflow.',
      imageUrl: '../../assets/images/services/crm.svg',
      fade: 350
    },
    {
      title: 'Automation',
      description: 'Automate your customer service, marketing, sales and more.',
      imageUrl: '../../assets/images/services/automation.svg',
      fade: 350
    },
    {
      title: 'UI/UX Design',
      description: 'Let us help you enhance your user experience and design.',
      imageUrl: '../../assets/images/services/ux.svg',
      fade: 400
    },
    {
      title: 'SEO Optimization',
      description: 'Increase your chances of being found online through multiple channels.',
      imageUrl: '../../assets/images/services/seo.svg',
      fade: 600
    }
  ];

  return (
    <div className={styles.container}>
      <Container>
        <Fade up delay={250}>
          <div className={styles.title}>Custom Software</div>
        </Fade>
        <Fade up delay={500}>
          <div className={styles.description}>For small and large companies.</div>
        </Fade>
        <div className={styles.tiles}>
          {servicesInfo.map(service => {
            return (
              <div className={styles.tile} key={service.title}>
                <img className={styles.tileImage} src={service.imageUrl} />
                <div className={styles.tileTitle}>{service.title}</div>
                <div className={styles.tileDescription}>{service.description}</div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};
