import * as React from 'react';
import { Link } from 'react-router-dom';

// styles
import * as styles from './FooterLink.sass';

interface IProps {
  href?: string;
  to?: string;
  text?: string;
}

export const FooterLink: React.FC<IProps> = props => {
  if (props.to) {
    return (
      <Link className={styles.footerLink} to={props.to}>
        {props.text}
      </Link>
    );
  }
  return (
    <a className={styles.footerLink} href={props.href} rel="noreferrer noopener" target="_blank">
      {props.text}
    </a>
  );
};
