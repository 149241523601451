exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.HomePage__container__3h0Y- {\n  background-color: #FFF;\n  padding: 20px; }\n\n.HomePage__paddingBottom__2DEJ2 {\n  padding-bottom: 350px; }\n\n.HomePage__paddingTop__1_BU6 {\n  padding-top: 100px; }\n", ""]);

// exports
exports.locals = {
	"container": "HomePage__container__3h0Y-",
	"paddingBottom": "HomePage__paddingBottom__2DEJ2",
	"paddingTop": "HomePage__paddingTop__1_BU6"
};