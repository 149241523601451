import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

// components
import { About, AboutHero, CTABanner } from '../../components';

// context
import { MenuContext } from '../../contexts';

// styles
// import * as styles from './AboutPage.sass';

export const AboutPage: React.FC = () => {
  const { setSelectedMenuItem } = useContext(MenuContext);

  useEffect(() => {
    setSelectedMenuItem('about');
  }, []);

  const bannerProps = {
    buttonText: 'Get In Touch',
    subtitle: 'ARE YOU READY?',
    text: 'Let us build your next application.',
    title: 'Get a Quote'
  };

  return (
    <div>
      <Helmet
        title="About"
        meta={[
          {
            name: 'description',
            content: 'About Austin Code Shop'
          },
          {
            name: 'keywords',
            content: 'ACS acs austincodeshop austin code shop about'
          }
        ]}
      />
      <AboutHero />
      <About />
      <CTABanner
        buttonText={bannerProps.buttonText}
        subtitle={bannerProps.subtitle}
        text={bannerProps.text}
        title={bannerProps.title}
      />
    </div>
  );
};
