import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

// components
import { Contact } from '../../components';
// context
import { MenuContext } from '../../contexts';

// styles
// import * as styles from './ContactPage.sass';

export const ContactPage: React.FC = () => {
  const { setSelectedMenuItem } = useContext(MenuContext);

  useEffect(() => {
    setSelectedMenuItem('contact');
  }, []);

  return (
    <div>
      <Helmet
        title="Contact"
        meta={[
          {
            name: 'description',
            content: 'Contact Austin Code Shop'
          },
          {
            name: 'keywords',
            content: 'ACS acs austincodeshop austin code shop contact email message'
          }
        ]}
      />
      <Contact />
    </div>
  );
};
