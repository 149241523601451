import * as React from 'react';
import { useState } from 'react';

// components
import { FeatureItem } from '../index';

// styles
import * as styles from './FeaturesList.sass';

interface IProps {
  services?: IService[]
}

interface IService {
  serviceDescription: string;
  serviceImage: 'automation' | 'cloudSoftware' | 'mobileApps' | 'website';
  serviceName: string;
}

export const FeaturesList: React.FC<IProps> = () => {
  const [services] = useState<IService[]>([
    {
      serviceDescription:
      "Your website is not just an online placeholder. It should bring you business, set you apart, and inform your customer what they can expect when working with you. Whatever your vision and needs, we'll deliver a site that works for you.",
      serviceImage: 'website',
      serviceName: 'Websites'
    },
    {
      serviceDescription:
      "Whether it's an internal app for your team, or an app to keep in touch with new and existing customers, we build iOS and Android apps which will grow your business.",
      serviceImage: 'mobileApps',
      serviceName: 'Mobile Apps'
    },
    {
      serviceDescription:
      'Power your frontend applications with User Authentication, Ecommerce, File Handling and Sending Email.',
      serviceImage: 'cloudSoftware',
      serviceName: 'Cloud Software'
    },
    {
      serviceDescription:
      'Automate any part of your business for an increased performance your customers will notice! Our automation solutions will remove any obstacles in your business - from your warehouse operations, to sales or customer outreach.',
      serviceImage: 'automation',
      serviceName: 'Automation'
    }
  ]);

  return (
    <div className={styles.featuresList}>
      {services.map((service, idx) => {
        return <FeatureItem
          description={service.serviceDescription}
          image={service.serviceImage}
          key={service.serviceName}
          name={service.serviceName}
          position={idx % 2 === 0 ? 'right' : 'left'}
        />;
      })}
    </div>
  );
};
