import * as classNames from 'classnames';
import * as React from 'react';
import { useContext } from 'react';

// contexts
import { SocialMediaMenuContext } from '../../contexts';

// styles
import * as styles from './SocialMediaMenu.sass';

interface IProps {
  className?: string;
}

export const SocialMediaMenu: React.FC<IProps> = ({ className }) => {
  // const { selectedMenuItem } = useContext(MenuContext);
  const { socialMediaMenuItems } = useContext(SocialMediaMenuContext);

  const platforms = { facebook: styles.facebook, github: styles.github, twitter: styles.twitter };

  // const platformsLight = {
  //   facebook: styles.facebookWhite,
  //   github: styles.githubWhite,
  //   twitter: styles.twitterWhite
  // };

  return (
    <ul className={classNames(styles.socialMediaMenu, className)}>
      {socialMediaMenuItems.map(socialMediaMenuItem => {
        const platformStyle = platforms[socialMediaMenuItem.platform];
        // selectedMenuItem === 'home'
        //   ? (platformStyle = platforms[socialMediaMenuItem.platform])
        //   : (platformStyle = platformsLight[socialMediaMenuItem.platform]);

        return (
          <li className={styles.listItem} key={socialMediaMenuItem.platform}>
            <a
              className={classNames(styles.icon, platformStyle)}
              href={socialMediaMenuItem.href}
              rel="noreferrer noopener"
              target="_blank"
            >
              {socialMediaMenuItem.text}
            </a>
          </li>
        );
      })}
    </ul>
  );
};
