exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.Heading__heading__1iQBC {\n  font-family: 'CocoGoose';\n  margin: 0 0 20px 0;\n  position: relative; }\n\nh1.Heading__heading__1iQBC {\n  color: #111;\n  font-size: 3.8rem;\n  text-transform: lowercase; }\n  @media (max-width: 600px) {\n    h1.Heading__heading__1iQBC {\n      font-size: 2.2rem; } }\n\nh2.Heading__heading__1iQBC {\n  color: #111;\n  font-size: 3rem;\n  text-transform: lowercase; }\n  @media (max-width: 600px) {\n    h2.Heading__heading__1iQBC {\n      font-size: 2rem; } }\n\nh3.Heading__heading__1iQBC {\n  color: #111;\n  font-size: 2rem;\n  text-transform: lowercase; }\n  @media (max-width: 600px) {\n    h3.Heading__heading__1iQBC {\n      font-size: 1.6rem; } }\n\nh4.Heading__heading__1iQBC {\n  color: #111;\n  font-size: 1.5rem;\n  text-transform: lowercase; }\n  @media (max-width: 600px) {\n    h4.Heading__heading__1iQBC {\n      font-size: 1.2rem; } }\n\nh5.Heading__heading__1iQBC {\n  font-size: 3rem; }\n\nh6.Heading__heading__1iQBC {\n  font-size: 3rem; }\n", ""]);

// exports
exports.locals = {
	"heading": "Heading__heading__1iQBC"
};