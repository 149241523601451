import * as React from 'react';
import * as classNames from 'classnames';
import { useState, useEffect } from 'react';

// components
import { Heading } from '..';

// styles
import * as styles from './ExamplesRedesign.sass';

interface IProps {
  className?: string;
}

export const ExamplesRedesign: React.SFC<IProps> = ({ children }) => {
  const [slideshowImageIndex, setSlideshowImageIndex] = useState(0);

  const examples = [
    {
      name: `Arlo's Curbside`,
      video: 'https://static.austincodeshop.com/videos/arlos.mp4',
      description: 'Web Development, UX/UI Design',
      url: 'https://www.arloscurbside.com/'
    },
    {
      name: 'EatPastry',
      video: 'https://static.austincodeshop.com/videos/eatpastry.mp4',
      description: 'Web Development, UX/UI Design',
      url: 'https://www.eatpastry.com/'
    },
    {
      name: 'Highway Barricades & Services',
      video: 'https://static.austincodeshop.com/videos/hbstexas.mp4',
      description: 'Web Development, UX/UI Design',
      url: 'https://hbstexas.com/'
    },
    {
      name: 'Vegan Mexican Food',
      video: 'https://static.austincodeshop.com/videos/veganmexicanfood.mp4',
      description: 'Web Development, UX/UI Design',
      url: 'https://veganmexicanfood.com/'
    }
  ];

  const timer = setInterval(() => {
    nextSlide();
  }, 10 * 1000);

  const nextSlide = () => {
    if (slideshowImageIndex === examples.length - 1) {
      setSlideshowImageIndex(0);
    } else {
      setSlideshowImageIndex(slideshowImageIndex + 1);
    }
    clearInterval(timer);
  };

  const previousSlide = () => {
    if (slideshowImageIndex === 0) {
      setSlideshowImageIndex(examples.length - 1);
    } else {
      setSlideshowImageIndex(slideshowImageIndex - 1);
    }
    clearInterval(timer);
  };

  useEffect(() => {
    // const timer = setInterval(() => {
    //   nextSlide();
    // }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, [slideshowImageIndex]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Heading className={styles.title} level={2}>
          Work that makes us proud
        </Heading>
        <div className={styles.slideshow}>
          <a
            className={classNames(styles.example)}
            href={examples[slideshowImageIndex].url}
            rel="noreferrer noopener"
            target="_blank"
          >
            {slideshowImageIndex > -1 && (
              <span className={styles.scene}>
                <span className={styles.loader} />
                <video
                  autoPlay
                  playsInline
                  loop
                  muted
                  className={styles.video}
                  src={examples[slideshowImageIndex].video}
                />
                <span className={styles.laptop} />
                <span className={styles.laptopGlare} />
              </span>
            )}
          </a>
          <button
            className={classNames(styles.arrow, styles.arrowLeft)}
            onClick={() => previousSlide()}
          />
          <button
            className={classNames(styles.arrow, styles.arrowRight)}
            onClick={() => nextSlide()}
          />
        </div>
        <div
          className={classNames(
            styles.imageDescription,
            slideshowImageIndex === 0 ? styles.imageDescriptionFadein1 : null,
            slideshowImageIndex === 1 ? styles.imageDescriptionFadein2 : null,
            slideshowImageIndex === 2 ? styles.imageDescriptionFadein3 : null,
            slideshowImageIndex === 3 ? styles.imageDescriptionFadein4 : null,
            slideshowImageIndex === 4 ? styles.imageDescriptionFadein5 : null,
            slideshowImageIndex === 5 ? styles.imageDescriptionFadein6 : null
          )}
        >
          <div className={styles.exampleName}>{examples[slideshowImageIndex].name}</div>
          <div className={styles.exampleDescription}>
            {examples[slideshowImageIndex].description}
          </div>
        </div>
        <div className={styles.indicatorContainer}>
          {examples.map((example, index) => (
            <button
              className={classNames(
                styles.indicator,
                slideshowImageIndex === index ? styles.indicatorActive : null
              )}
              key={example.name}
              onClick={() => setSlideshowImageIndex(index)}
              title={`${example.name} - ${example.description}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
