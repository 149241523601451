exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Archivo+Narrow:700|Cantata+One|PT+Sans+Narrow:700|Playfair+Display:900|Quicksand:700|Roboto|Work+Sans:900&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);", ""]);

// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.ErrorPage__icon__1GDoJ {\n  background-image: url(\"../../assets/images/icons/not-found.svg\");\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: center;\n  display: inline-block;\n  height: 128px;\n  width: 128px; }\n\n.ErrorPage__wrapper__3lef9 {\n  padding: 100px 0;\n  text-align: center; }\n", ""]);

// exports
exports.locals = {
	"icon": "ErrorPage__icon__1GDoJ",
	"wrapper": "ErrorPage__wrapper__3lef9"
};