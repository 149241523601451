import * as React from 'react';

// components
import { Container } from '../container/Container';
import { ServiceFeaturesTile } from '../serviceFeaturesTile/ServiceFeaturesTile';

// styles
import * as styles from './ServiceFeatures.sass';

export const ServiceFeatures: React.SFC = () => {
  const featuresData = [
    {
      image: <img className={styles.image} src="../../assets/images/services/custom.svg" />,
      title: 'Your business. Your software.',
      subtitle: 'Fully Customizable',
      description:
        'Your business is unique and ever-changing. Your software should be, too. Let us build custom software to give your company a competitive edge.'
    },
    {
      image: <img className={styles.image} src="../../assets/images/services/code.svg" />,
      title: 'Smarter Coding for Faster Results',
      subtitle: 'Rapid Development',
      description:
        'Software is a living, breathing thing. It requires maintenance and updates. Together, we will plan small to large development cycles as your technology needs change. We discover, design, develop, deploy, test and support your requirements on an ongoing basis.'
    },
    {
      image: <img className={styles.image} src="../../assets/images/services/scale.svg" />,
      title: 'Scale Up or Down',
      subtitle: 'Flexibility',
      description:
        'Your software needs can change at any moment. Quickly scale up or scale down your tech as needed. For your ever-changing software needs, we offer discounts for weekly, monthly or yearly contracts.'
    }
  ];

  return (
    <div className={styles.main}>
      <Container className={styles.container}>
        <div className={styles.textContainerProcess}>
          <div>
            <ServiceFeaturesTile
              image={featuresData[0].image}
              title={featuresData[0].title}
              description={featuresData[0].description}
              subtitle={featuresData[0].subtitle}
              side="right"
            />
            <ServiceFeaturesTile
              image={featuresData[1].image}
              title={featuresData[1].title}
              description={featuresData[1].description}
              subtitle={featuresData[1].subtitle}
            />
            <ServiceFeaturesTile
              image={featuresData[2].image}
              title={featuresData[2].title}
              description={featuresData[2].description}
              subtitle={featuresData[2].subtitle}
              side="right"
            />
          </div>
        </div>
      </Container>
    </div>
  );
};
