import * as classNames from 'classnames';
import * as React from 'react';

// styles
import * as styles from './Container.sass';

interface IProps {
  className?: string;
}

export const Container: React.SFC<IProps> = ({ children, className }) => (
  <div className={classNames(styles.container, className)}>
    {children}
  </div>
);
