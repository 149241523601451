import * as React from 'react';
import * as classNames from 'classnames';

// components
import { Button, Subheading } from '..';

// styles
import * as styles from './Examples2.sass';

interface IProps {
  showButton?: boolean;
  className?: string;
}

export const Examples2: React.FC<IProps> = ({ showButton = true, className }) => {
  const examples = [
    {
      exampleDescription:
        '',
      exampleImage: 'veganuary',
      exampleName: "Veganuary",
      url: 'https://www.veganuary.com'
    },
    {
      exampleDescription:
        'Arlo’s serves plant-based comfort food in a bistro style setting for the Austin nightlife scene. The image carousels in this site will make you ravenous for burgers and tacos.',
      exampleImage: 'arlos',
      exampleName: "Arlo's Curbside",
      url: 'https://www.arloscurbside.com'
    },
    {
      exampleDescription:
        "EatPastry makes cookie dough and cookie dough bites for every kind of cookie monster out there! Their website features an animated cookie dough land you'll want to get lost in.",
      exampleImage: 'eatPastry',
      exampleName: 'EatPastry',
      url: 'https://www.eatpastry.com'
    },
    {
      exampleDescription:
        '57West Capital Advisors is a financial advisory firm with headquarters in Austin, TX. This website features content in both English and Spanish which is stored in a backend service and rendered dynamically in the browser.',
      exampleImage: '57west',
      exampleName: '57West Capital Advisors',
      url: 'https://www.57west.us'
    },
    {
      exampleDescription:
        'Highway Barricades and Services (HBS) is one of the premier woman-owned businesses in South Texas. This website features custom animations to mimic the look and behavior of LED-light construction signs.',
      exampleImage: 'hbs',
      exampleName: 'Highway Barricades & Services',
      url: 'https://www.hbstexas.com'
    },
    {
      exampleDescription:
        'This website features a wealth of authentic Mexican food recipes. In addition to the website, we built a custom CMS for easy recipe maintenance along with capabilities to upload hi-res, mouth-watering food photography.',
      exampleImage: 'veganMexicanFood',
      exampleName: 'Vegan Mexican Food',
      url: 'https://www.veganmexicanfood.com'
    },
    {
      exampleDescription:
        'This website features a wealth of authentic Filipinx food recipes. In addition to the website, we built a custom CMS for easy recipe maintenance along with capabilities to upload hi-res, mouth-watering food photography.',
      exampleImage: 'vff',
      exampleName: 'Vegan Filipino Food',
      url: 'https://www.veganfilipinofood.com'
    }
  ];

  return (
    <div id="examples2" className={classNames(className, styles.examples)}>
      <div className={styles.container}>
        <Subheading title="Our Work" description="Below are some of our favorites." />
        <div className={styles.examplesContainer}>
          {examples.map(example => {
            return (
              <a
                href={example.url}
                className={styles.exampleItem}
                key={example.exampleImage}
                rel="noreferrer noopener"
                target="_blank"
              >
                <div
                  className={classNames(
                    example.exampleImage === 'veganuary' ? styles.veganuary : null,
                    example.exampleImage === 'arlos' ? styles.arlos : null,
                    example.exampleImage === 'hbs' ? styles.hbs : null,
                    example.exampleImage === 'veganMexicanFood' ? styles.veganMexicanFood : null,
                    example.exampleImage === 'eatPastry' ? styles.eatPastry : null,
                    example.exampleImage === 'vff' ? styles.veganFilipinoFood : null,
                    example.exampleImage === '57west' ? styles._57west : null,
                    styles.exampleImage
                  )}
                />
                <h3 className={styles.exampleItemName}>{example.exampleName}</h3>
                {/* <p className={styles.exampleItemDescription}>{example.exampleDescription}</p> */}
              </a>
            );
          })}
        </div>
        {showButton && <Button to="/portfolio">View Portfolio</Button>}
      </div>
    </div>
  );
};
