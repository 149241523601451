import * as classNames from 'classnames';
import * as React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

// components
// import { Button } from '../button/Button';

// contexts
// import { PageContext } from '../../contexts';
import { MenuIconContext } from '../../contexts';

// styles
import * as styles from './ResponsiveMenu.sass';

// props
interface IProps {
  className?: string;
}

export const ResponsiveMenu: React.SFC<IProps> = ({ className }) => {
  // const { page } = useContext(PageContext);
  const { setMenuIcon } = useContext(MenuIconContext);

  let menuItems = [
    {
      name: 'home',
      text: 'Home',
      url: '/'
    },
    {
      name: 'portfolio',
      text: 'Portfolio',
      url: '/portfolio'
    },
    {
      name: 'services',
      text: 'Services',
      url: '/services'
    },
    {
      name: 'about',
      text: 'About',
      url: '/about'
    },
    {
      name: 'contact',
      text: 'Contact',
      url: '/contact'
    }
  ];

  const closeMenu = () => {
    const body = document.getElementById('body');
    const menuButton = document.getElementById('menuLinks');
    const menuBackground = document.getElementById('menuBackground');
    setMenuIcon('../../assets/images/icons/menu-button.png');
    menuButton.setAttribute('style', 'width: 0');
    menuBackground.setAttribute('style', 'width: 0');
    body.style.overflow = 'visible';
    body.style.position = 'static';
    body.setAttribute('style', 'overflow: visible; position: static;');
  };

  return (
    <div id="menuBackground" className={classNames(styles.main, className)}>
      <div id="menuLinks" className={styles.menuLinks}>
        <Link onClick={closeMenu} className={styles.menuLink} to={menuItems[0].url}>
          {menuItems[0].text}
        </Link>
        <Link onClick={closeMenu} className={styles.menuLink} to={menuItems[1].url}>
          {menuItems[1].text}
        </Link>
        <Link onClick={closeMenu} className={styles.menuLink} to={menuItems[2].url}>
          {menuItems[2].text}
        </Link>
        <Link onClick={closeMenu} className={styles.menuLink} to={menuItems[3].url}>
          {menuItems[3].text}
        </Link>
        <Link onClick={closeMenu} className={styles.menuLink} to={menuItems[4].url}>
          {menuItems[4].text}
        </Link>
      </div>
    </div>
  );
};
