import * as classNames from 'classnames';
import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

// styles
import * as styles from './Button.sass';

interface IProps {
  className?: string;
  color?: 'primary' | 'secondary' | 'black' | 'white';
  disabled?: boolean;
  duration?: number;
  icon?: string;
  offset?: number;
  smooth?: boolean;
  to?: string;
  type?: 'submit' | 'reset' | 'button' | 'link';
}

export const Button: React.FC<IProps> = ({
  children,
  className = '',
  color = 'primary',
  disabled = false,
  duration = 1000,
  icon = '',
  offset = 0,
  smooth = false,
  to = '',
  type = 'button'
}) => {
  const buttonClassNames = classNames(styles.button, className, styles[color]);
  const content = icon ?
    (
      <span className={styles.container}>
        <span className={styles.text}>{children}</span>
        <span className={styles.icon} style={{ backgroundImage: `url(${icon})` }} />
      </span>
    ) : children;

  if (smooth) {
    return (
      <ScrollLink className={buttonClassNames} disabled={disabled} duration={duration} offset={offset} smooth={true} to={to}>
        {content}
      </ScrollLink>
    );
  }

  if (type === 'link' || to) {
    if (to.substr(0, 5) === 'http:' || to.substr(0, 6) === 'https:') {
      return (
        <a
          className={buttonClassNames}
          href={to}
          rel="noreferrer noopener"
          target="_blank"
        >
          {content}
        </a>
      );
    }
    return (
      <RouterLink
        className={buttonClassNames}
        to={to}
      >
        {content}
      </RouterLink>
    );
  }

  return (
    <button className={buttonClassNames} disabled={disabled}>
      {content}
    </button>
  );
}