import * as React from 'react';
import { useState, useEffect } from 'react';

// components
// import { Container, Subheading } from '../index';

// styles
import * as styles from './FeaturesRedesign.sass';

export const FeaturesRedesign: React.FC = () => {
  const images = [
    ['android-os-device', 'circle-apple-tv', 'car-satellite'],
    ['cloud-laptop-file-upload', 'circle-cloud-server-network', 'futuristic-medical-curing-tank-2'],
    ['game-pad', 'circle-drone', 'gopro'],
    ['heartrate-reader', 'circle-iphone-gesture-scale-up', 'home-theater'],
    ['iphone-message-notification', 'circle-panorama-camera-phone', 'programming-encrypt-lock'],
    ['satellite-communication-center', 'circle-smart-watch-chat-emoji', 'satellite'],
    ['webcam', 'circle-virtual-reality', '3d-printer-1']
  ];
  const [imagesIndex, setImagesIndex] = useState(0);
  const [width, setWidth] = useState(0);

  const switchImages = () => {
    if (imagesIndex === images.length - 1) {
      setImagesIndex(0);
    } else {
      setImagesIndex(imagesIndex + 1);
    }
  };

  const handleScroll = () => {
    const newWidth = window.scrollY * 0.1;

    setWidth(newWidth);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      switchImages();
    }, 2000);
    return () => {
      clearInterval(timer);
    };
  }, [imagesIndex]);

  return (
    <div className={styles.services}>
      <div className={styles.container}>
        <div className={styles.title}>We can build anything</div>
        <div className={styles.subtitle}>Websites, mobile apps, APIs, voice control + more</div>
        <div
          style={{
            width: `calc(${width}px + 80%)`
          }}
          className={styles.imageGrid}
        >
          <div className={styles.flexContainer}>
            <img
              className={styles.image}
              src={`../../assets/images/features/${images[imagesIndex][0]}.svg`}
            />
          </div>
          <div className={styles.flexContainer}>
            <img
              className={styles.imageCenter}
              src={`../../assets/images/features/${images[imagesIndex][1]}.svg`}
            />
          </div>
          <div className={styles.flexContainer}>
            <img
              className={styles.image}
              src={`../../assets/images/features/${images[imagesIndex][2]}.svg`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
