exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.FeaturesList__featuresList__2IiOy {\n  color: #111; }\n", ""]);

// exports
exports.locals = {
	"featuresList": "FeaturesList__featuresList__2IiOy"
};