import * as React from 'react';

// components
import { Heading } from '../../components';

// styles
import * as styles from './ServicesHero.sass';

interface IProps {
  className?: string;
}

export const ServicesHero: React.SFC<IProps> = ({ children }) => (
  <div className={styles.container}>
    <div className={styles.leftContainer} />
    <div className={styles.rightContainer}>
      <Heading level={2}>
        What we do. Differently.
      </Heading>
      <div className={styles.subtitle}>
        We used fixed time and fixed budget which allows us to deliver our projects on schedule and within our client’s budgets.
      </div>
    </div>
  </div>
);
