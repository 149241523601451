import * as React from 'react';
import * as classNames from 'classnames';

// components
import { Button } from '../../components';

// styles
import * as styles from './CTABanner.sass';

interface IProps {
  buttonText: string;
  subtitle: string;
  text: string;
  title: string;
  className?: string;
}

export const CTABanner: React.FC<IProps> = ({ buttonText, subtitle, text, title, className }) => {
  return (
    <div className={classNames(styles.ctaBanner, className)}>
      {/* <h3 className={styles.subtitle}>{subtitle}</h3> */}
      <h2 className={styles.title}>Let's work together</h2>
      <p className={styles.subtitle}>Ready to talk about your tech? You dream it. We build it.</p>
      <a href="/contact">
        <Button>Get a Quote</Button>
      </a>
    </div>
  );
};
