exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.ExampleImage__eatPastry__btCVN,\n.ExampleImage__veganMexicanFood__3WcTD {\n  background-color: #BDBEC0;\n  background-position: center center;\n  background-size: cover;\n  border-radius: 10px;\n  height: 300px;\n  padding: 0 25px;\n  margin: 0 auto;\n  width: 450px;\n  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1); }\n  @media (max-width: 1000px) {\n    .ExampleImage__eatPastry__btCVN,\n    .ExampleImage__veganMexicanFood__3WcTD {\n      width: 80%;\n      height: 350px;\n      background-position: center top;\n      margin-top: 90px; } }\n  @media (max-width: 678px) {\n    .ExampleImage__eatPastry__btCVN,\n    .ExampleImage__veganMexicanFood__3WcTD {\n      width: 100%; } }\n\n.ExampleImage__eatPastry__btCVN {\n  background-image: url(\"../../assets/images/examples/eat-pastry-demo.png\"); }\n\n.ExampleImage__veganMexicanFood__3WcTD {\n  background-image: url(\"../../assets/images/examples/vegan-mexican-food-demo.png\"); }\n\n.ExampleImage__arlos__2KRCN {\n  background-image: url(\"../../assets/images/examples/arlos.png\"); }\n\n.ExampleImage__hbs__2I0kx {\n  background-image: url(\"../../assets/images/examples/hbs.png\"); }\n", ""]);

// exports
exports.locals = {
	"eatPastry": "ExampleImage__eatPastry__btCVN",
	"veganMexicanFood": "ExampleImage__veganMexicanFood__3WcTD",
	"arlos": "ExampleImage__arlos__2KRCN",
	"hbs": "ExampleImage__hbs__2I0kx"
};