import * as React from 'react';
import { useState, useEffect } from 'react';
// import * as classNames from 'classnames';

// components
import { Button, Heading, HeroScrollDown } from '..';

// styles
import * as styles from './Hero.sass';

// css transitions group

// used it for arlos

export const Hero: React.FC = () => {
  const [degrees, setDegrees] = useState(0);

  const handleScroll = () => {
    const newDegrees = window.scrollY * 0.01;

    setDegrees(newDegrees);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={styles.hero}>
      <div>
        <img
          style={{
            transform: `rotate(${-degrees * 1.5 - 8}deg)`
          }}
          src="/assets/images/hero/nintendo-switch-2.svg"
          className={styles.heroImage2}
        />
        <img
          style={{
            transform: `rotate(${degrees + 4}deg)`
          }}
          src="/assets/images/hero/desktop.svg"
          className={styles.heroImage3}
        />
        <img
          style={{
            transform: `rotate(${degrees}deg)`
          }}
          src="/assets/images/hero/server.svg"
          className={styles.heroImage6}
        />
        <img
          style={{
            transform: `rotate(${degrees}deg)`
          }}
          src="/assets/images/hero/cloud.svg"
          className={styles.heroImage5}
        />
        <img
          style={{
            transform: `rotate(${degrees + 2}deg)`
          }}
          src="/assets/images/hero/flow-charts.svg"
          className={styles.heroImage1}
        />
        <img
          style={{
            transform: `rotate(${degrees + 2}deg)`
          }}
          src="/assets/images/hero/coffee.svg"
          className={styles.heroImage4}
        />
        <div className={styles.container}>
          <div>
            <Heading level={1} className={styles.title}>
              We write code
            </Heading>
            <div className={styles.subtitle}>
              Let us take care of your tech so you can take care of business.
            </div>
            <div className={styles.buttonContainer}>
              <Button to="/contact">Get a Quote</Button>
            </div>
            <div className={styles.subtitle2}>
              Or check out some of our{' '}
              <a className={styles.link} href="/portfolio">
                favorite work
              </a>
              .
            </div>
            <HeroScrollDown />
          </div>
        </div>
      </div>
    </div>
  );
};
