exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.Subheading__description__1c9oz {\n  color: #87898C;\n  font-family: 'Shree';\n  font-size: 16px;\n  margin: 0;\n  text-align: center; }\n  @media screen and (max-width: 600px) {\n    .Subheading__description__1c9oz {\n      font-size: 14px; } }\n\n.Subheading__subheading__3XwHV {\n  display: flex;\n  flex: 0 0 100%;\n  flex-direction: column;\n  align-items: center;\n  padding: 0 20px; }\n\n.Subheading__subtitle__2RJA3 {\n  color: #AB40FF;\n  font-family: 'Shree';\n  font-size: 12px;\n  letter-spacing: 5px;\n  margin: 9.96px 0;\n  text-transform: uppercase; }\n\n.Subheading__title__1KuVZ {\n  color: #111;\n  font-size: 32px;\n  font-family: 'CocoGoose';\n  margin: 21.4px 0;\n  text-align: center;\n  text-transform: lowercase; }\n  @media screen and (max-width: 600px) {\n    .Subheading__title__1KuVZ {\n      font-size: 26px; } }\n", ""]);

// exports
exports.locals = {
	"description": "Subheading__description__1c9oz",
	"subheading": "Subheading__subheading__3XwHV",
	"subtitle": "Subheading__subtitle__2RJA3",
	"title": "Subheading__title__1KuVZ"
};