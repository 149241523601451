import * as React from 'react';
import { useState } from 'react';

// components
import { FeaturesList, Subheading } from '../index';

// styles
import * as styles from './Features.sass';

interface IProps {
  description?: string;
  subtitle?: string;
  title?: string;
}

export const Features: React.FC = () => {
  const [subheading] = useState<IProps>({
    description: "We'll build it to your requirements - on time and on budget.",
    subtitle: 'NEED CUSTOM SOFTWARE?',
    title: "That's exactly what we do!"
  });

  return (
    <div className={styles.services}>
      <div className={styles.container}>
        <Subheading
          description={subheading.description}
          subtitle={subheading.subtitle}
          title={subheading.title}
        />
        <FeaturesList />
      </div>
    </div>
  );
};
