import * as React from 'react';
import * as classNames from 'classnames';
// import { Link } from 'react-router-dom';
import { useState } from 'react';

import { FooterLink } from '../index';

// styles
import * as styles from './Footer.sass';

export const Footer: React.FC = () => {
  const [name] = useState('');
  const [email, setEmail] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  // const [isComplete, setIsComplete] = useState(false);
  const [message, setMessage] = useState('');

  const navigationLinks = [
    {
      text: 'Home',
      to: '/'
    },
    {
      text: 'Portfolio',
      to: '/portfolio'
    },
    {
      text: 'Services',
      to: '/services'
    },
    {
      text: 'About',
      to: './about'
    },
    {
      text: 'Contact',
      to: './contact'
    }
  ];

  const socialLinks = [
    {
      text: 'Twitter',
      href: 'https://twitter.com/austincodeshop'
    },
    {
      text: 'Facebook',
      href: 'https://www.facebook.com/austincodeshop'
    },
    {
      text: 'Github',
      href: 'https://github.com/austincodeshop'
    }
  ];

  async function handleSubmit(event: any) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await fetch('https://api.austincodeshop.com/subscribe', {
        method: 'POST',
        body: JSON.stringify({
          name,
          email
        })
      });
      setEmail('');
      setMessage("Thank you! You've been added to our mailing list.");
    } catch (error) {
      if (error.message) {
        setMessage(error.message);
      } else {
        setMessage(
          "We're sorry, our servers were unable to process your submission. Please try again or shoot us an email and we'll add you to our mailing list ASAP!"
        );
      }
    }
    setIsLoading(false);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={classNames(styles.subsection, styles.subsection1)}>
          <h3 className={styles.logo}>
            <div className={styles.austin}>Austin</div> Code Shop
          </h3>
        </div>
        <div className={styles.subsection}>
          <h4 className={styles.subsectionHeading}>NAVIGATION</h4>
          <ul className={styles.links}>
            {navigationLinks.map(link => (
              <FooterLink text={link.text} key={link.text} to={link.to} />
            ))}
          </ul>
        </div>
        <div className={styles.subsection}>
          <h4 className={styles.subsectionHeading}>SOCIAL</h4>
          <ul className={styles.links}>
            {socialLinks.map(link => (
              <FooterLink href={link.href} key={link.text} text={link.text} />
            ))}
          </ul>
        </div>
        <div className={styles.subsection}>
          <h4 className={styles.subsectionHeading}>CONTACT</h4>
          <ul className={styles.links}>
            <li>
              <a
                className={styles.emailLink}
                href="mailto:info@austincodeshop.com"
                rel="noreferrer noopener"
              >
                info@austincodeshop.com
              </a>
            </li>
          </ul>
          <h4 className={styles.subsectionHeading}>NEWSLETTER</h4>
          <ul className={styles.links}>
            <li>
              <form onSubmit={handleSubmit}>
                <div className={styles.inputLabel}>Email:</div>
                <input
                  className={styles.inputText}
                  type="text"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <button
                  disabled={isLoading}
                  className={styles.inputSubmit}
                  type="submit"
                  value="Sign Up"
                >
                  {isLoading ? 'Loading...' : 'Submit'}
                </button>
              </form>
            </li>
          </ul>
          {message && <div className={styles.message}>{message}</div>}
        </div>
      </div>
      <div className={styles.copyright}>&copy; 2019 Austin Code Shop</div>
    </div>
  );
};
