import * as classNames from 'classnames';
import * as React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

// components
import { Button } from '../../components';

// contexts
import { MenuContext } from '../../contexts';

// styles
import * as styles from './Menu.sass';

interface IProps {
  className?: string;
}

export const Menu: React.FC<IProps> = ({ className }) => {
  const { menuItems, selectedMenuItem } = useContext(MenuContext);

  return (
    <nav className={styles.menu}>
      <ul className={styles.list}>
        {menuItems.map(menuItem => (
          <li className={styles.listItem} key={menuItem.name}>
            <Link
              className={classNames(
                styles.link,
                menuItem.name === selectedMenuItem ? styles.selected : null,
                styles.linkBlack
                // selectedMenuItem === 'home' ? styles.linkBlack : styles.linkWhite
              )}
              to={menuItem.url}
            >
              {menuItem.text}
            </Link>
          </li>
        ))}
        <Button color="black" icon="/assets/images/icons/user.svg" to="https://portal.austincodeshop.com">
          Client Portal
        </Button>
      </ul>
    </nav>
  );
};
