import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

// components
import { CTABanner, Examples2, Hero, FeaturesRedesign, Testimonials } from '../../components';

// context
import { MenuContext } from '../../contexts';

// styles
import * as styles from './HomePage.sass';

export const HomePage: React.FC = () => {
  const { setSelectedMenuItem } = useContext(MenuContext);

  useEffect(() => {
    setSelectedMenuItem('home');
  }, []);

  const bannerProps = {
    buttonText: 'Contact Us',
    subtitle: 'READY TO TALK ABOUT YOUR TECH?',
    text: 'Fast & On Budget.',
    title: "Let's Work Together"
  };

  return (
    <>
      <Helmet
        title="Home"
        meta={[
          {
            name: 'description',
            content: 'Austin Code Shop'
          },
          {
            name: 'keywords',
            content: 'ACS acs austincodeshop austin code shop'
          }
        ]}
      />
      <Hero />
      <FeaturesRedesign />
      {/* <Features /> */}
      <Examples2 className={styles.paddingBottom} />
      <Testimonials />
      <CTABanner
        buttonText={bannerProps.buttonText}
        subtitle={bannerProps.subtitle}
        text={bannerProps.text}
        title={bannerProps.title}
        className={styles.paddingTop}
      />
    </>
  );
};
