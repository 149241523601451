exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.HeroScrollDown__scrollDown__10Gtb {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  margin-top: 120px; }\n\n.HeroScrollDown__scrollDownText__3uYze {\n  color: #87898C;\n  font-family: 'CocoGoose';\n  font-size: 12px;\n  margin-bottom: 12px;\n  text-transform: lowercase; }\n  @media (max-width: 768px) {\n    .HeroScrollDown__scrollDownText__3uYze {\n      display: none; } }\n\n.HeroScrollDown__scrollDownIcon__HyvXA {\n  background-image: url(\"./assets/images/hero/scroll-down.svg\");\n  background-repeat: no-repeat;\n  background-size: 12px auto;\n  height: 12px;\n  margin-bottom: 24px;\n  width: 12px; }\n  @media (max-width: 768px) {\n    .HeroScrollDown__scrollDownIcon__HyvXA {\n      display: none; } }\n", ""]);

// exports
exports.locals = {
	"scrollDown": "HeroScrollDown__scrollDown__10Gtb",
	"scrollDownText": "HeroScrollDown__scrollDownText__3uYze",
	"scrollDownIcon": "HeroScrollDown__scrollDownIcon__HyvXA"
};