import * as React from 'react';
import { useState } from 'react';

// components
import { Subheading } from '../index';

// styles
import * as styles from './Examples.sass';

export const Examples: React.FC = () => {
  const [subheading] = useState({
    subtitle: 'Our Favorites',
    title: 'Selected Works'
  });

  return (
    <div className={styles.examples}>
      <div className={styles.container}>
        <Subheading subtitle={subheading.subtitle} title={subheading.title} />
      </div>
    </div>
  );
};
