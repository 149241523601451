import * as React from 'react';
import { useContext, useEffect } from 'react';
import { Fade } from 'react-reveal';

// contexts
import { HeroTextContext } from '../../contexts';

// styles
import * as styles from './HeroDetails.sass';

export const HeroDetails: React.FC = () => {
  const { heroTextItems, heroTextItemsIndex, setHeroTextItemsIndex } = useContext(HeroTextContext);

  const switchText = () => {
    if (heroTextItemsIndex === heroTextItems.length - 1) {
      setHeroTextItemsIndex(0);
    } else {
      setHeroTextItemsIndex(heroTextItemsIndex + 1);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      switchText();
    }, 3000);
    return () => {
      clearInterval(timer);
    };
  }, [heroTextItemsIndex]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>Be proud of your</div>
      <div className={styles.textLoop}>
        <Fade up delay={200}>
          {heroTextItems[heroTextItemsIndex].text}
        </Fade>
        <span className={styles.textLoopBlack}>.</span>
      </div>
    </div>
  );
};
