exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.Technologies__container__3xk2j {\n  background-color: #AB40FF;\n  margin: 50px 0 0 0;\n  padding: 100px 20px; }\n\n.Technologies__description__3K6U7 {\n  font-size: 1rem;\n  font-weight: 100;\n  color: #BDBEC0;\n  width: 100%;\n  text-align: center; }\n\n.Technologies__subtitle__1agAE {\n  font-size: 0.9rem;\n  font-weight: 100;\n  text-transform: uppercase;\n  color: #BDBEC0;\n  letter-spacing: 0.3rem;\n  width: 100%;\n  text-align: center; }\n\n.Technologies__tile__rG-kz {\n  width: calc((100% - 150px) / 3);\n  display: inline-block;\n  padding: 25px 25px 50px 25px;\n  color: #BDBEC0;\n  border-radius: 20px;\n  margin: 25px;\n  font-size: 1rem;\n  text-align: center; }\n  @media (max-width: 500px) {\n    .Technologies__tile__rG-kz {\n      width: calc((100% - 150px) / 2); } }\n\n.Technologies__tileDescription__2jazW {\n  font-weight: 100;\n  font-size: 0.9rem;\n  line-height: 1.3rem; }\n\n.Technologies__tileImage__2aLtm {\n  width: 96px;\n  height: 96px;\n  margin: 0 auto; }\n\n.Technologies__tileTitle__320pv {\n  color: #FFF;\n  font-weight: 100;\n  font-size: 1.1rem;\n  margin: 18px 0; }\n\n.Technologies__tiles__2wDvU {\n  list-style: none;\n  margin: 50px 0 0 0;\n  padding: 0;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center; }\n\n.Technologies__title__2GZ8X {\n  width: 100%;\n  text-align: center;\n  font-size: 2rem;\n  font-family: CocoGoose;\n  color: #FFF;\n  margin: 25px 0;\n  text-transform: lowercase; }\n", ""]);

// exports
exports.locals = {
	"container": "Technologies__container__3xk2j",
	"description": "Technologies__description__3K6U7",
	"subtitle": "Technologies__subtitle__1agAE",
	"tile": "Technologies__tile__rG-kz",
	"tileDescription": "Technologies__tileDescription__2jazW",
	"tileImage": "Technologies__tileImage__2aLtm",
	"tileTitle": "Technologies__tileTitle__320pv",
	"tiles": "Technologies__tiles__2wDvU",
	"title": "Technologies__title__2GZ8X"
};