import classNames from 'classnames';
import * as React from 'react';
import { Fade } from 'react-reveal';

// styles
import * as styles from './ServiceFeaturesTile.sass';

interface IProps {
  className?: string;
  image: any;
  side?: 'left' | 'right';
  step?: string;
  title: string;
  subtitle: string;
  description: string;
}

export const ServiceFeaturesTile: React.SFC<IProps> = ({
  image,
  side = 'left',
  step,
  title,
  subtitle,
  description
}) => {
  const contentElement = (
    <div>
      {step && (
        <div className={styles.step}>
          <Fade up>{step}</Fade>
        </div>
      )}
      <div className={styles.subtitle}>
        <Fade up delay={200}>
          {subtitle}
        </Fade>
      </div>
      <div className={styles.title}>
        {title}
      </div>
      {/* <div className={styles.bar} /> */}
      <div className={styles.description}>
        {description}
      </div>
    </div>
  );

  if (side === 'right') {
    return (
      <div className={styles.main}>
        <div className={styles.left}>{contentElement}</div>
        <div className={classNames(styles.right, styles.imageContainer)}>{image}</div>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <div className={classNames(styles.left, styles.imageContainer)}>{image}</div>
      <div className={styles.right}>{contentElement}</div>
    </div>
  );
};
