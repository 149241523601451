import * as classNames from 'classnames';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

// styles
import * as styles from './CookieNotice.sass';

export const CookieNotice: React.FC = props => {
  const [hasAcceptedCookies, setAcceptedCookies] = useState(
    localStorage.getItem('hidePrivacyNotice') === 'true'
  );
  const [isUnwrapped, setIsUnwrapped] = useState(false);

  useEffect(() => {
    setIsUnwrapped(true);
  }, []);

  return (
    <CSSTransition in={!hasAcceptedCookies} timeout={1000} unmountOnExit>
      <div className={classNames(styles.wrapper, isUnwrapped && !hasAcceptedCookies ? styles.unwrapped : null)}>
        <div className={styles.container}>
          <p className={styles.text}>
            This website uses cookies to ensure you get the best experience on our website. View our <a className={styles.link}> Terms of Use</a> and <a className={styles.link}> Privacy Policy</a>.
          </p>
          <button
            onClick={() => setAcceptedCookies(() => {
              localStorage.setItem('hidePrivacyNotice', 'true');
              return true;
            })}
            className={styles.button}
          >
            Got It!
          </button>
        </div>
      </div>
    </CSSTransition>
  );
};
