import * as React from 'react';

// components
import { Button, Heading } from '../../components';

// styles
import * as styles from './PortfolioHero.sass';

interface IProps {
  className?: string;
}

export const PortfolioHero: React.SFC<IProps> = ({ children }) => (
  <div className={styles.container}>
    <div className={styles.leftContainer}>
      <Heading level={2}>
        We pour our heart into our code
      </Heading>
      <div className={styles.subtitle}>
        Every project matters to us. Please check some of our favorite works.
      </div>
      <Button
        duration={500}
        icon="/assets/images/icons/heart.svg"
        offset={50}
        smooth={true}
        to="examples2"
      >
        Our Favorites
      </Button>
    </div>
    <div className={styles.rightContainer} />
  </div>
);
