import * as React from 'react';
import { Dispatch, SetStateAction, createContext, useState } from 'react';

interface IProps {
  heroTextItems: any[];
  setHeroTextItems: Dispatch<SetStateAction<any[]>>;
  heroTextItemsIndex: number;
  setHeroTextItemsIndex: Dispatch<SetStateAction<number>>;
}

export const HeroTextContext = createContext<IProps>({
  heroTextItems: [
    {
      text: 'website'
    },
    {
      text: 'iOS App'
    },
    {
      text: 'Android App'
    },
    {
      text: 'Rest API'
    },
    {
      text: 'UI/UX Design'
    },
    {
      text: 'CRM Software'
    },
    {
      text: 'VHM Software'
    },
    {
      text: 'Accounting Software'
    },
    {
      text: 'eCommerce Platform'
    }
  ],
  setHeroTextItems: () => {},
  heroTextItemsIndex: 0,
  setHeroTextItemsIndex: () => {}
});

export const HeroTextContextProvider: React.SFC = props => {
  const [heroTextItems, setHeroTextItems] = useState([
    {
      text: 'website'
    },
    {
      text: 'iOS App'
    },
    {
      text: 'Android App'
    },
    {
      text: 'Rest API'
    },
    {
      text: 'UI/UX Design'
    },
    {
      text: 'CRM Software'
    },
    {
      text: 'VHM Software'
    },
    {
      text: 'Accounting Software'
    },
    {
      text: 'eCommerce Platform'
    }
  ]);
  const [heroTextItemsIndex, setHeroTextItemsIndex] = useState(0);

  const value = { heroTextItems, setHeroTextItems, heroTextItemsIndex, setHeroTextItemsIndex };

  return <HeroTextContext.Provider value={value}>{props.children}</HeroTextContext.Provider>;
};

export const HeroTextContextConsumer = HeroTextContext.Consumer;
