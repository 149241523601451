exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.CTABanner__ctaBanner__357DJ {\n  background: #FFF;\n  color: #111;\n  font-family: 'Shree';\n  padding: 150px 40px 150px 40px;\n  text-align: center; }\n\n.CTABanner__ctaLink__1IRQ5 {\n  background-color: #FFF;\n  border-radius: 20px;\n  color: #FFF;\n  font-size: 18px;\n  padding: 10px 25px 7px 25px;\n  text-decoration: none; }\n  .CTABanner__ctaLink__1IRQ5:hover {\n    text-decoration: none; }\n\n.CTABanner__subtitle__zlA5o {\n  color: #BDBEC0;\n  font-family: 'Shree';\n  font-size: 1.2rem;\n  margin: 10px 0;\n  text-align: center;\n  z-index: 11;\n  position: relative; }\n\n.CTABanner__title__Im5YP {\n  font-size: 2.5rem;\n  font-family: 'CocoGoose';\n  margin: 21.4px 0;\n  text-align: center;\n  text-transform: lowercase;\n  z-index: 11;\n  position: relative; }\n  @media screen and (max-width: 600px) {\n    .CTABanner__title__Im5YP {\n      font-size: 2.2rem; } }\n\n.CTABanner__text__2oGpO {\n  color: #111;\n  margin: 0;\n  padding-bottom: 25px; }\n", ""]);

// exports
exports.locals = {
	"ctaBanner": "CTABanner__ctaBanner__357DJ",
	"ctaLink": "CTABanner__ctaLink__1IRQ5",
	"subtitle": "CTABanner__subtitle__zlA5o",
	"title": "CTABanner__title__Im5YP",
	"text": "CTABanner__text__2oGpO"
};