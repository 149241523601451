import * as React from 'react';

// styles
import * as styles from './ExampleImage.sass';

interface IProps {
  image: 'eatPastry' | 'veganMexicanFood' | 'arlos' | 'hbs';
}

export const ExampleImage: React.FC<IProps> = props => {
  return <div className={styles[props.image]} />;
};
