import * as React from 'react';
import { Dispatch, SetStateAction, createContext, useState } from 'react';

interface IProps {
  menuItems: IMenuItem[];
  setMenuItems: Dispatch<SetStateAction<IMenuItem[]>>;
  selectedMenuItem: string;
  setSelectedMenuItem: Dispatch<SetStateAction<string>>;
}

interface IMenuItem {
  name: string;
  text: string;
  url: string;
}

export const MenuContext = createContext<IProps>({
  menuItems: [],
  setMenuItems: () => {},
  selectedMenuItem: '',
  setSelectedMenuItem: () => {}
});

export const MenuContextProvider: React.SFC = props => {
  const [menuItems, setMenuItems] = useState([
    {
      name: 'home',
      text: 'Home',
      url: '/'
    },
    {
      name: 'portfolio',
      text: 'Portfolio',
      url: '/portfolio'
    },
    {
      name: 'services',
      text: 'Services',
      url: '/services'
    },
    {
      name: 'about',
      text: 'About',
      url: '/about'
    },
    {
      name: 'contact',
      text: 'Contact',
      url: '/contact'
    }
  ]);
  const [selectedMenuItem, setSelectedMenuItem] = useState('');

  const value = { menuItems, setMenuItems, selectedMenuItem, setSelectedMenuItem };

  return <MenuContext.Provider value={value}>{props.children}</MenuContext.Provider>;
};

export const MenuContextConsumer = MenuContext.Consumer;
