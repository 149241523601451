exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.FeatureItem__automation__3MaiJ {\n  background-image: url(\"/assets/images/features/automation.svg\"); }\n\n.FeatureItem__cloudSoftware__1Cn4D {\n  background-image: url(\"/assets/images/features/cloud.svg\"); }\n\n.FeatureItem__featureDetails__1AhJF {\n  width: 50%;\n  display: flex;\n  align-items: center; }\n  @media (max-width: 768px) {\n    .FeatureItem__featureDetails__1AhJF {\n      width: 100%; } }\n\n.FeatureItem__featureDetailsContainer__1uvD1 {\n  height: auto; }\n\n.FeatureItem__featureItemDescription__2SoH2 {\n  margin: 0 0 16px;\n  padding: 0 25px; }\n\n.FeatureItem__featureItem__2WArr {\n  display: flex;\n  font-family: 'Shree';\n  height: 500px;\n  justify-content: center;\n  padding: 100px 0; }\n  @media (max-width: 768px) {\n    .FeatureItem__featureItem__2WArr {\n      display: block;\n      height: auto;\n      padding: 100px 0 0 0; } }\n\n.FeatureItem__featureItemLink__gn7Eo {\n  color: #111;\n  font-size: 12px;\n  font-weight: bold;\n  letter-spacing: 5px;\n  padding: 0 25px; }\n  .FeatureItem__featureItemLink__gn7Eo:hover {\n    color: #87898C;\n    text-decoration: none; }\n\n.FeatureItem__featureItemName__1QZGL {\n  color: #AB40FF;\n  font-size: 24px;\n  max-width: 100%;\n  padding: 0 25px;\n  font-weight: 100; }\n\n.FeatureItem__imageContainer__1w9NU {\n  width: 50%;\n  padding: 0 20px; }\n  @media (max-width: 768px) {\n    .FeatureItem__imageContainer__1w9NU {\n      width: 100%; } }\n\n.FeatureItem__mobileApps__3Hu2k {\n  background-image: url(\"/assets/images/features/app.svg\"); }\n\n.FeatureItem__right__2OP9e {\n  order: 1; }\n\n.FeatureItem__website__G0w41 {\n  background-image: url(\"/assets/images/features/website.svg\"); }\n", ""]);

// exports
exports.locals = {
	"automation": "FeatureItem__automation__3MaiJ",
	"cloudSoftware": "FeatureItem__cloudSoftware__1Cn4D",
	"featureDetails": "FeatureItem__featureDetails__1AhJF",
	"featureDetailsContainer": "FeatureItem__featureDetailsContainer__1uvD1",
	"featureItemDescription": "FeatureItem__featureItemDescription__2SoH2",
	"featureItem": "FeatureItem__featureItem__2WArr",
	"featureItemLink": "FeatureItem__featureItemLink__gn7Eo",
	"featureItemName": "FeatureItem__featureItemName__1QZGL",
	"imageContainer": "FeatureItem__imageContainer__1w9NU",
	"mobileApps": "FeatureItem__mobileApps__3Hu2k",
	"right": "FeatureItem__right__2OP9e",
	"website": "FeatureItem__website__G0w41"
};