exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@font-face {\n  font-family: 'CocoGoose';\n  src: url(\"./assets/fonts/cocogoose.otf\") format(\"opentype\"); }\n\n@font-face {\n  font-family: 'Shree';\n  src: url(\"./assets/fonts/shree.otf\") format(\"opentype\"); }\n\n.SocialMediaMenu__facebook__11ZRK {\n  background-image: url(\"/assets/images/icons/facebook-black.svg\"); }\n\n.SocialMediaMenu__facebookWhite__2I0aO {\n  background-image: url(\"/assets/images/icons/facebook-white.svg\"); }\n\n.SocialMediaMenu__github__2YCjY {\n  background-image: url(\"/assets/images/icons/github-black.svg\"); }\n\n.SocialMediaMenu__githubWhite__Gtke5 {\n  background-image: url(\"/assets/images/icons/github-white.svg\"); }\n\n.SocialMediaMenu__icon___-Tkl {\n  background-position: center center;\n  background-repeat: no-repeat;\n  background-size: contain;\n  color: transparent;\n  display: block;\n  height: 24px;\n  width: 24px;\n  transition: transform .5s; }\n  .SocialMediaMenu__icon___-Tkl:hover {\n    transform: scale(1.3); }\n\n.SocialMediaMenu__list__1I6Hb {\n  list-style: none; }\n\n.SocialMediaMenu__listItem__Rr9Zh {\n  height: 24px;\n  list-style: none;\n  margin-left: 40px; }\n  .SocialMediaMenu__listItem__Rr9Zh:first-of-type {\n    margin-left: 30px; }\n\n.SocialMediaMenu__socialMediaMenu__s4T9W {\n  display: flex;\n  padding: 0; }\n  @media (max-width: 1040px) {\n    .SocialMediaMenu__socialMediaMenu__s4T9W {\n      display: none; } }\n\n.SocialMediaMenu__twitter___uKeR {\n  background-image: url(\"/assets/images/icons/twitter-black.svg\"); }\n\n.SocialMediaMenu__twitterWhite__1k05D {\n  background-image: url(\"/assets/images/icons/twitter-white.svg\"); }\n", ""]);

// exports
exports.locals = {
	"facebook": "SocialMediaMenu__facebook__11ZRK",
	"facebookWhite": "SocialMediaMenu__facebookWhite__2I0aO",
	"github": "SocialMediaMenu__github__2YCjY",
	"githubWhite": "SocialMediaMenu__githubWhite__Gtke5",
	"icon": "SocialMediaMenu__icon___-Tkl",
	"list": "SocialMediaMenu__list__1I6Hb",
	"listItem": "SocialMediaMenu__listItem__Rr9Zh",
	"socialMediaMenu": "SocialMediaMenu__socialMediaMenu__s4T9W",
	"twitter": "SocialMediaMenu__twitter___uKeR",
	"twitterWhite": "SocialMediaMenu__twitterWhite__1k05D"
};